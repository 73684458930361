@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

option {
    color: #000;
}

.white-background {
    background-color: #fff !important;
}

.margin-top-100 {
    margin-top: 100px;
}

.margin-top-110 {
    margin-top: 110px;
}

.margin-top-110 {
  margin-top: 110px;
}

.text-center {
    text-align: center;
}

.dropdown {
    position: relative;
    overflow: visible;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    z-index: 1000;
    border-radius: 6px;
    overflow: visible;
}

.dropdown-content button {
    color: #000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border: olive;
}

.dropdown-button:hover,
.dropdown-content button:hover {
    background-color: #152153;
    color: #fff;
}

.dropdown-button {
    width: 100%;
    background-color: #f9f9f9;
    color: #000;
}

.dropdown-button-top {
    border-radius: 5px 5px 0 0;
}

.dropdown-button-bottom {
    border-radius: 0 0 5px 5px;
}

.dropdown-button-single,
.row-menu-button {
    border-radius: 5px;
}

.row-menu-button {
    padding: 2px 16px;
}

.dropdown-left {
    left: -9.2vw;
    top: -3.05vw;
}

.dropdown-left-single {
    left: -9.2vw;
    top: -0.8vw;
}

.elipsis-table {
    position: relative;
    width: 30px;
    margin-right: auto;
    margin-left: 0;
}

.modal-input-text {
    background-color: #f9f9f9 !important;
    color: #000 !important;
}

.modal-input-text:disabled {
    color: #000 !important;
    opacity: 1;
}

.table-container-overflow {
    overflow-x: unset !important;
    overflow: visible !important;
}

.dropBtn {
    align-items: right;
}

.white-background {
    background-color: #fff !important;
}

.display-none {
    display: none;
}

.red-text {
    color: red;
}