.markdown li {
  margin-left: 30px;
}

.markdown ul {
    margin-bottom: 10px;
}

.markdown h3 {
    font-weight: 800;
    font-size: large;
}

.markdown p:not(:has(+ ul)) {
    margin-bottom: 10px;
}

.markdown hr {
    display: none;
}   